import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    private _storage: Storage | null = null;

    constructor(private storage: Storage) {
    }

    private async init() {
        if (this._storage !== null) return;
        await this.storage.defineDriver(CordovaSQLiteDriver);
        const storage = await this.storage.create();
        this._storage = storage;
    }

    // Create and expose methods that users of this service can
    // call, for example:
    async set<T>(key: string, value: T) {
        await this.init();
        return this._storage.set(key, value);
    }

    async get<T>(key: string): Promise<T> {
        await this.init();
        return this._storage.get(key);
    }

    async remove(key: string) {
        await this.init();
        return this._storage.remove(key);
    }
}
