import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { environment } from '../../environments/environment';
import { Browser } from '@capacitor/browser';
import { Router } from '@angular/router';
import { StorageService } from '../storage.service';


@Injectable({
    providedIn: 'root'
})
export class OAuthLoginService {
    constructor(private httpClient: HttpClient,
        private router: Router,
        private storageService: StorageService,
        private zone: NgZone) { }

    login() {
        const url = environment.serviceNowHost + "/oauth_auth.do";

        const queryParams = new URLSearchParams();
        queryParams.set("client_id", environment.authOptions.clientId);
        queryParams.set("response_type", environment.authOptions.responseType);

        switch (Capacitor.getPlatform()) {
            case "android": {
                queryParams.set("redirect_uri", environment.authOptions.androidRedirectUri);
                break;
            }
            case "ios": {
                queryParams.set("redirect_uri", environment.authOptions.iosRedirectUri);
                break;
            }

            case "web": {
                queryParams.set("redirect_uri", environment.authOptions.webRedirectUri);
                window.location.href = url + "?" + queryParams.toString();
                return;
            }
        }
        
        Browser.open({
            url: url + "?" + queryParams.toString()
        });
    }

    async logout() {
        if(Capacitor.isNativePlatform()) window.screen.orientation.lock("portrait");
        document.getElementById("footer")?.classList.remove("landscape-hide");
        const url = environment.apiHost + "/oauth_revoke_token.do";
        const queryParams = new HttpParams();
        queryParams.set("token", (await this.storageService.get<string>("access_token")));

        await this.storageService.remove("access_token");
        await this.storageService.remove("access_token_expiry");

        await this.httpClient.get(url, {
            params: queryParams
        })?.toPromise(); 
        await this.zone.run(() => this.router.navigateByUrl("/login", { replaceUrl: true }));
    }
}
