import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import { from, Observable } from 'rxjs';
import { OAuthLoginService } from '../login/oauth-login.service';
import { StorageService } from '../storage.service';

@Injectable({
    providedIn: 'root'
})
export class AuthCheckerGuard implements CanActivate, CanActivateChild {

    constructor(private storageService: StorageService,
        private oAuthService: OAuthLoginService) { }

    /**
     * Check if activation of route is allowed.
     * @param route the activated route
     * @param state the current router state
     * @returns boolean if activation is allowed.
     */
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
            return from(this.check())
    }

    /**
     * Check if activation of child route is allowed.
     * @param childRoute
     * @param state
     * @returns
     * @see {@link canActivate}
     */
    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.canActivate(childRoute, state);
    }


    async check() {
        const expiry = await this.storageService.get<string>("access_token_expiry");
        if (await this.storageService.get<string>("access_token") === null || expiry === null || Date.now() >= parseInt(expiry)) {
            await this.oAuthService.logout();
            return false;
        }

        return true;
    }
}
