import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthCheckerGuard } from './auth-checker.guard';
import { HomePage } from './home.page';

const routes: Routes = [
  {
    path: "",
    component: HomePage,
    canActivate: [AuthCheckerGuard],
    canActivateChild: [AuthCheckerGuard],
    children: [
      {
        path: "find_hardware",
        loadChildren: () => import('./find-hardware/find-hardware.module').then(m => m.FindHardwareModule)
      },
      {
        path: "receive_hardware",
        loadChildren: () => import('./receive-hardware/receive-hardware.module').then(m => m.ReceiveHardwareModule)
      },
      {
        path: 'deliver_hardware',
        loadChildren: () => import('./deliver-hardware/deliver-hardware.module').then( m => m.DeliverHardwarePageModule)
      },
      {
        path: 'return_to_stock',
        loadChildren: () => import('./return-to-stock/return-to-stock.module').then( m => m.ReturnToStockModule)
      },
      {
        path: 'audit_stockroom',
        loadChildren: () => import('./audit-stockroom/audit-stockroom.module').then( m => m.AuditStockroomPageModule)
      },
      {
        path: 'audit_user',
        loadChildren: () => import('./audit-user/audit-user.module').then( m => m.AuditUserPageModule)
      },
      {
        path: 'audit_location',
        loadChildren: () => import('./audit-location/audit-location.module').then( m => m.AuditLocationPageModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomePageRoutingModule {}

