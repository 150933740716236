import { AfterViewInit, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthLoginService } from '../login/oauth-login.service';
import { MenuComponent } from './menu/menu.component';
import { NavController, Platform } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { StorageService } from '../storage.service';
import { Capacitor } from '@capacitor/core';

@Component({
    selector: 'app-home',
    templateUrl: './home.page.html',
    styleUrls: ['./home.page.scss']
})
export class HomePage implements OnInit, AfterViewInit {

    currentTitle: string;
    siteState: number = 0;

    @ViewChild(MenuComponent) menu: MenuComponent;


    constructor(private router: Router,
        private activatedRoute: ActivatedRoute,
        private oAuthService: OAuthLoginService,
        private storageService: StorageService,
        public toastController: ToastController,
        private zone: NgZone) { }

    async ngAfterViewInit() {
        if(Capacitor.isNativePlatform()) await window.screen.orientation.lock("portrait");
        document.getElementById("footer").classList.remove("landscape-hide");
    }

    ngOnInit() {
        this.activatedRoute.url.subscribe(async () => {
            await this.storageService.get<string>("error_redirect").then(url => {
                this.zone.run(() => {
                    if(url !== null) this.router.navigateByUrl(url);
                    else this.router.navigateByUrl("/home/find_hardware");
                });
            });
        });
    }



    /**
     * Performs session-logout and access-token-invalidation.
     */
    performLogout() {
        this.oAuthService.logout();
    }

    /**
     * Creates a new toast for displaying a message.
     * @param message the message to display in the toast
     * @param cssClass the class to set in the toast
     * @param callback optional callback function to execute
     */
    async presentToast(message: string, cssClass: string) {
        const toast = await this.toastController.create({
            message: message,
            duration: 2000,
            cssClass: cssClass
        });
        await toast.present();
    }
}
