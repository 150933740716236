import { AfterViewInit, Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { APIService } from '../api.service';
import { StorageService } from '../storage.service';
import { OAuthLoginService } from './oauth-login.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.page.html',
    styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit, AfterViewInit {

    isDarkTheme: boolean;

    constructor(private router: Router,
        private activatedRoute: ActivatedRoute,
        private oAuthLoginService: OAuthLoginService,
        private apiService: APIService,
        private storageService: StorageService,
        private zone: NgZone) { }

    ngOnInit() {
        this.isDarkTheme = window.matchMedia("(prefers-color-scheme: dark)").matches;

        if (!Capacitor.isNativePlatform()) {
            this.activatedRoute.url.subscribe(() => {
                this.checkForCallback(this.router.url);
            });
        } else {
            App.addListener("appUrlOpen", (data) => {
                this.checkForCallback(data.url);
            });
        }
    }

    ngAfterViewInit() {
        window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", (e) => this.isDarkTheme = e.matches);
    }

    async checkForCallback(url: string) {
        if (/.*\/login\/callback\/?#/.test(url)) {
            if (Capacitor.getPlatform() === "ios") Browser.close();
            const queryString = url.replace(/.*\/login\/callback\/?#/, "?");
            const params = new URLSearchParams(queryString);

            const access_token = params.get("access_token");
            const expiry = params.get("expires_in");

            if (access_token !== undefined && expiry !== undefined) {
                await this.storageService.set("access_token", access_token);
                await this.storageService.set("access_token_expiry", String(Date.now() + parseInt(expiry) * 1000 - 10 * 60 * 100)); //Force to reLogin 10 minutes before access-token expires so current update sessions could be finished.

                this.apiService.dummyRequest().then((status: number) => {
                    this.zone.run(() => {
                        if (status === 200) {
                            this.router.navigateByUrl("/home", { replaceUrl: true });
                        } else this.router.navigateByUrl("/login", { replaceUrl: true });
                    });
                });
            }
        }
    }

    performLogin() {
        this.oAuthLoginService.login();
    }
}
