import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { from, Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { OAuthLoginService } from './login/oauth-login.service';
import { StorageService } from './storage.service';
/**
 * Service for interacting with the ServiceNow-RestAPI.
 */
@Injectable({
    providedIn: 'root'
})
export class APIService {

    constructor(private httpClient: HttpClient,
        private storageService: StorageService,
        private oAuthLoginService: OAuthLoginService,
        public toastController: ToastController) { }

    /**
     * Constructs basic-headers and append to param.
     * @param headers
     * @returns the {@link HttpHeaders} appended with the basic headers
     */
    private async constructBasicHeaders(headers: HttpHeaders) {
        return headers.set("Accept", "application/json")
            .set("Content-Type", "application/json")
            .set("Authorization", "Bearer " + (await this.storageService.get<string>("access_token")));
    }

    /**
     * Dummy request for checking access-token-validity.
     * @returns http status code
     */
    async dummyRequest(): Promise<number> {
        const params = new HttpParams().set("sysparm_limit", 1).set("sysparm_fields", "sys_id").set("dummy", "true");
        console.log("Sending dummy request for checking access-token validity.");

        let statusCode = -1;

        await this.doTableApiRequest("GET", "sys_user", params).toPromise().then((res) => {
            statusCode = res.status;
        });

        return statusCode;
    }

    doApiRequest(method: string,
        url: string,
        params?: HttpParams,
        headers = new HttpHeaders(),
        useDefaultHeaders = true,
        body: any = {}) {

        return from(useDefaultHeaders ? this.constructBasicHeaders(headers) : Promise.resolve(headers)).pipe(mergeMap((headers) => {
            return this.httpClient.request(method, url, {
                body: body,
                headers: headers,
                params: params,
                observe: "response" as const,
                responseType: "text" as const
            }) as Observable<HttpResponse<any>>;
        }), catchError(err => {
            if (err.error instanceof Error) {
                console.error('An internal error occured: ', err.error.message);

                this.presentToast('An internal error occured: ' + err.error.message);

                return of({status: 500});
            } else {
                console.error('Api request did not complete properly. Status:', err.status);
                this.presentToast('Api request did not complete properly. Status: ' + err.status);

                if(params.get("dummy") !== null)
                    return of({status: err.status})
                else {
                    if(err.status === 401) {
                        this.oAuthLoginService.logout();
                    }
                    return of({status: err.status});
                }
            }
        }));
    }

    doTableApiRequest(method: string,
        table: string,
        params?: HttpParams,
        pathParams: string = null,
        body: any = {},
        headers = new HttpHeaders()) {
        return this.doApiRequest(method, environment.apiHost + "/api/now/table/" + table + (pathParams === null ? "" : "/" + pathParams), params, headers, true, body);
    }

    private async presentToast(message: string) {
        const toast = await this.toastController.create({
            message: message,
            duration: 2000,
            cssClass: "toast-error"
        });
        await toast.present();
    }
}
