import { Injectable } from '@angular/core';
import { BarcodeScanner, CameraDirection } from '@capacitor-community/barcode-scanner';


@Injectable({
    providedIn: 'root'
})
export class BarcodeScannerService {

    scanActive: boolean = false;
    private scanPermission: boolean = false;

    constructor() {}

    async startScan(): Promise<string> {
        if (!this.scanPermission) {
            const status = await BarcodeScanner.checkPermission({ force: true });
            this.scanPermission = status.granted;
            if (!this.scanPermission) return;
        }

        this.scanActive = true;

        await BarcodeScanner.hideBackground();
        document.body.classList.add("qrscanner");
        const result = await BarcodeScanner.startScan({ cameraDirection: CameraDirection.BACK });
        document.body.classList.remove("qrscanner");
        await BarcodeScanner.showBackground();
        this.scanActive = false;

        if (result.hasContent) return result.content;
        else return null;
    };

    async stopScan() {
        if (!this.scanActive) return;

        await BarcodeScanner.showBackground();
        document.body.classList.remove("qrscanner");
        await BarcodeScanner.stopScan();
        this.scanActive = false;
    };
}
