// This file can be replaced during build by using the `` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  serviceNowHost: "https://zumtobeldev.service-now.com",
  apiHost: "https://zgstockappproxy.azurewebsites.net/proxy",
  authOptions: {
    clientId: "97bb412cfbd15510f21f87eedde684cd",
    responseType: "token",
    iosRedirectUri: "stockapp://com.zumtobelgroup.stockapp/login/callback",
    androidRedirectUri: "stockapp://com.zumtobelgroup.stockapp/login/callback",
    webRedirectUri: "https://stockapp.zgrp.net/login/callback"
  },

  incident_assignment_groups: {
    enterprise_desktop_support: "6800b40837167500c74e261953990ee5",
    stock_management: "ba8826f20f399240612a936792050ebb"
  },
  asset_states: [
    {
        display_name: "-- None --",
        value: "",
        substates: [
            {
                display_name: "-- None --",
                value: ""
            }
        ]
    },
    {
        display_name: "On order",
        value: "2",
        substates: [
            {
                display_name: "-- None --",
                value: ""
            }
        ]
    },
    {
        display_name: "In stock",
        value: "6",
        substates: [
            {
                display_name: "-- None --",
                value: ""
            },
            {
                display_name: "Available",
                value: "available"
            },
            {
                display_name: "Reserved",
                value: "reserved"
            },
            {
                display_name: "Defective",
                value: "defective"
            },
            {
                display_name: "Pending repair",
                value: "pending_repair"
            },
            {
                display_name: "Pending install",
                value: "pending_install"
            },
            {
                display_name: "Pending disposal",
                value: "pending_disposal"
            },
            {
                display_name: "Pending transfer",
                value: "pending_transfer"
            },
            {
                display_name: "Pre-allocated",
                value: "pre_allocated"
            }
        ]
    },
    {
        display_name: "In transit",
        value: "9",
        substates: [
            {
                display_name: "-- None --",
                value: ""
            },
            {
                display_name: "Available",
                value: "available"
            },
            {
                display_name: "Reserved",
                value: "reserved"
            },
            {
                display_name: "Defective",
                value: "defective"
            },
            {
                display_name: "Pending install",
                value: "pending_install"
            },
            {
                display_name: "Pending disposal",
                value: "pending_disposal"
            },
            {
                display_name: "Pre-allocated",
                value: "pre_allocated"
            }
        ]
    },
    {
        display_name: "In use",
        value: "1",
        substates: [
            {
                display_name: "-- None --",
                value: ""
            }
        ]
    },
    {
        display_name: "In maintenance",
        value: "3",
        substates: [
            {
                display_name: "-- None --",
                value: ""
            }
        ]
    },
    {
        display_name: "Retired",
        value: "7",
        substates: [
            {
                display_name: "-- None --",
                value: ""
            },
            {
                display_name: "Disposed",
                value: "disposed"
            },
            {
                display_name: "Sold",
                value: "sold"
            },
            {
                display_name: "Donated",
                value: "donated"
            },
            {
                display_name: "Vendor credit",
                value: "vendor_credit"
            },
            {
                display_name: "Pending disposal",
                value: "pending_disposal"
            }
        ]
    },
    {
        display_name: "Missing",
        value: "8",
        substates: [
            {
                display_name: "-- None --",
                value: ""
            },
            {
                display_name: "Lost",
                value: "lost"
            },
            {
                display_name: "Stolen",
                value: "stolen"
            }
        ]
    }
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
