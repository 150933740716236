import { Component, OnInit } from '@angular/core';
import { BarcodeScannerService } from './barcode-scanner.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

    barcodeScannerService: BarcodeScannerService;

    constructor(barcodeScannerServiceTemp: BarcodeScannerService) {
        this.barcodeScannerService = barcodeScannerServiceTemp;
    }

    ngOnInit() {
    }
}

